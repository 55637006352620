import { Action } from '@ngrx/store';
import { CompanyAttribute, CompanyContext, EditAttribute, SelectableAttribute } from 'app/shared/models';
import { CompanyComment } from 'app/shared/models/company/company-comment-model';

export enum Type {
  CONTEXT_ALREADY_SET = '[COMPANY_CONTEXT] CONTEXT_ALREADY_SET',
  SET_COMPANY_CONTEXT = '[COMPANY_CONTEXT] SET_COMPANY_CONTEXT',
  SET_COMPANY_CONTEXT_BY_TOKEN = '[COMPANY_CONTEXT] SET_COMPANY_CONTEXT_BY_TOKEN',
  SET_COMPANY_CONTEXT_BY_SHORT_NAME = '[COMPANY_CONTEXT] SET_COMPANY_CONTEXT_BY_SHORT_NAME',
  SET_COMPANY_CONTEXT_SUCCESS = '[COMPANY_CONTEXT] SET_COMPANY_CONTEXT_SUCCESS',
  ADD_COMPANY_COMMENT_CONTEXT = '[COMPANY_CONTEXT] ADD_COMPANY_COMMENT_CONTEXT',
  UPDATE_COMPANY_COMMENT_CONTEXT = '[COMPANY_CONTEXT] UPDATE_COMPANY_COMMENT_CONTEXT',
  DELETE_COMPANY_COMMENT_CONTEXT = '[COMPANY_CONTEXT] DELETE_COMPANY_COMMENT_CONTEXT',
  SAVE_COMPANY_ATTRIBUTES = '[COMPANY_CONTEXT] SAVE_COMPANY_ATTRIBUTES',
  SAVE_COMPANY_ATTRIBUTES_SUCCESS = '[COMPANY_CONTEXT] SAVE_COMPANY_ATTRIBUTES_SUCCESS'
}

export class SetCompanyContext implements Action {
  readonly type = Type.SET_COMPANY_CONTEXT;
  constructor(public payload: number, public onSuccess: Action[] = [], public force: boolean = false) { }
}

export class SetCompanyContextByToken implements Action {
  readonly type = Type.SET_COMPANY_CONTEXT_BY_TOKEN;
  constructor(public payload: { token: string }, public force: boolean = false) { }
}

export class SetCompanyContextByShortName implements Action {
  readonly type = Type.SET_COMPANY_CONTEXT_BY_SHORT_NAME;
  constructor(public payload: string, public onSuccess: Action[] = [], public force: boolean = false) { }
}

export class SetCompanyContextSuccess implements Action {

  readonly type = Type.SET_COMPANY_CONTEXT_SUCCESS;
  constructor(public payload: CompanyContext) { }
}

export class AddCompanyCommentContext implements Action {
  readonly type = Type.ADD_COMPANY_COMMENT_CONTEXT;
  constructor(public payload: CompanyComment) { }
}

export class UpdateCompanyCommentContext implements Action {
  readonly type = Type.UPDATE_COMPANY_COMMENT_CONTEXT;
  constructor(public payload: CompanyComment) { }
}

export class DeleteCompanyCommentContext implements Action {
  readonly type = Type.DELETE_COMPANY_COMMENT_CONTEXT;
  constructor(public payload: number) { }
}

export class ContextAlreadySet implements Action {
  readonly type = Type.CONTEXT_ALREADY_SET;
  constructor() { }
}

export class SaveCompanyAttributes implements Action {
  readonly type = Type.SAVE_COMPANY_ATTRIBUTES;
  constructor(public payload: EditAttribute[]) { }
}
export class SaveCompanyAttributesSuccess implements Action {
  readonly type = Type.SAVE_COMPANY_ATTRIBUTES_SUCCESS;
  constructor(public payload: CompanyAttribute[]) { }
}

export type Actions = SetCompanyContext | SetCompanyContextSuccess
  | SetCompanyContextByShortName | AddCompanyCommentContext
  | UpdateCompanyCommentContext | DeleteCompanyCommentContext
  | ContextAlreadySet | SaveCompanyAttributes | SaveCompanyAttributesSuccess;
